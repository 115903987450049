import React from "react"

export const QuestionIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 6.3C6.6134 6.3 6.3 6.6134 6.3 7C6.3 7.3866 6.6134 7.7 7 7.7C7.22187 7.7 7.45958 7.68646 7.7 7.64881V7C7.7 6.6134 7.3866 6.3 7 6.3Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0ZM7 6.3C6.6134 6.3 6.3 6.6134 6.3 7V7.7C6.3 8.0866 6.6134 8.4 7 8.4C7.3866 8.4 7.7 8.0866 7.7 7.7V7.64881C8.08879 7.58793 8.48465 7.46403 8.83204 7.23244C9.44433 6.82424 9.8 6.15392 9.8 5.25C9.8 4.45744 9.51063 3.80819 9.02558 3.35495C8.55229 2.91269 7.93763 2.69802 7.33955 2.66954C6.17197 2.61395 4.80872 3.28972 4.55952 4.78492C4.49597 5.16626 4.75358 5.52692 5.13492 5.59048C5.51626 5.65403 5.87692 5.39642 5.94048 5.01508C6.04128 4.41028 6.60303 4.03606 7.27295 4.06796C7.59362 4.08323 7.87584 4.19668 8.06973 4.37787C8.25187 4.54806 8.4 4.81757 8.4 5.25C8.4 5.74609 8.23067 5.95076 8.05546 6.06757C7.83016 6.21776 7.46933 6.3 7 6.3ZM7 9.1C6.4201 9.1 5.95 9.5701 5.95 10.15C5.95 10.7299 6.4201 11.2 7 11.2C7.5799 11.2 8.05 10.7299 8.05 10.15C8.05 9.5701 7.5799 9.1 7 9.1Z"
      fill="#939393"
    />
    <path
      d="M6.3 7.7C6.3 8.0866 6.6134 8.4 7 8.4C7.3866 8.4 7.7 8.0866 7.7 7.7V7.64881C7.45958 7.68646 7.22187 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V7.7Z"
      fill="white"
    />
    <path
      d="M7 6.3C7.3866 6.3 7.7 6.6134 7.7 7V7.64881C8.08879 7.58793 8.48465 7.46403 8.83204 7.23244C9.44433 6.82424 9.8 6.15392 9.8 5.25C9.8 4.45744 9.51063 3.80819 9.02558 3.35495C8.55229 2.91269 7.93763 2.69802 7.33955 2.66954C6.17197 2.61395 4.80872 3.28972 4.55952 4.78492C4.49597 5.16626 4.75358 5.52692 5.13492 5.59048C5.51626 5.65403 5.87692 5.39642 5.94048 5.01508C6.04128 4.41028 6.60303 4.03606 7.27295 4.06796C7.59362 4.08323 7.87584 4.19668 8.06973 4.37787C8.25187 4.54806 8.4 4.81757 8.4 5.25C8.4 5.74609 8.23067 5.95076 8.05546 6.06757C7.83016 6.21776 7.46933 6.3 7 6.3Z"
      fill="white"
    />
    <path
      d="M5.95 10.15C5.95 9.5701 6.4201 9.1 7 9.1C7.5799 9.1 8.05 9.5701 8.05 10.15C8.05 10.7299 7.5799 11.2 7 11.2C6.4201 11.2 5.95 10.7299 5.95 10.15Z"
      fill="white"
    />
  </svg>
)
